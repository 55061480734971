import React, { FC } from 'react';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

import { StoreProps } from './models';

import './Store.scss';

const Store: FC<{ data: StoreProps }> = ({
  data: {
    store: { urls, seo, name, title, image, buy, buyDescription, legals, items },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <div className="store">
        <div className="store__image">
          <Image imageData={image} alt={name} className="w-100" />
        </div>
        <div className="container section-container">
          <div className="row pt-5 mt-4 pb-4">
            <div className="col">
              <div className="store__header">
                <h2>{title}</h2>
                <hr />
                <h3>{buy}</h3>
                <p>{buyDescription}</p>
              </div>
              <div className="store__legals">
                <DangerouslySetInnerHtml html={legals} element="small" className="mb-0" />
              </div>
            </div>
          </div>
          <div className="row pb-5">
            {items.map(({ image: logo, link }) => (
              <div className="col-12 col-md-6 col-lg-4 mb-3" key={link.url}>
                <div className="store__item p-4 text-center">
                  <div>
                    <Image imageData={logo} alt={link.name} />
                  </div>
                  <a
                    href={link.url}
                    className="btn btn-golden mt-4"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {link.name}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    store(url: { eq: $url }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      name
      title
      image {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
      }
      buy
      buyDescription
      legals
      items {
        image {
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 340, quality: 100)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 340, quality: 100)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 340, quality: 100)
            }
          }
        }
        link {
          url
          name
        }
      }
    }
  }
`;

export default Store;
